import React from "react"
import { navigate, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import LogoDark from "../components/Common/LogoDark"
import { has } from "lodash"

const NotFoundPage = ({pageContext, location}) => (
  <Layout withHeader={false} withFooter={false}>
    <SEO title="404: Not found" />
    <div className="d-flex" style={{
      textAlign:"center",
      height:500,
      flexDirection:"column",
      justifyContent:"center",
      justifyItems:"center",
      alignItems:"center"
    }}>
      <LogoDark />
      <h1 className="display-4 mt-5">{has(location, 'state.message') ? location.state.message : 'YOU LOST!' }</h1>
      <p className="lead">You just hit a route that doesn&#39;t exist... the sadness.</p>
      <div className="col-2 text-center">
        <Link className="btn btn-secondary mt-5" to={`/`}>Back to Homepage</Link>
      </div>
      
    </div>
    
  </Layout>
)

export default NotFoundPage
